<template>
  <div class="mine">
    <div class="hdader_bg">
      <div class="user">
        <img
          v-if="user.headimgUrl != undefined"
          :src="user.headimgUrl"
          alt=""
        />
        <img v-if="user.headimgUrl == undefined" :src="users.src" alt="" />
      </div>
      <p
        style="
          width: 100%;
          font-size: 0.5rem;
          text-align: center;
          position: absolute;
          top: 28vh;
        "
        v-if="user.nickName != undefined"
      >
        {{ $t(user.nickName) }}
      </p>
      <p
        style="
          width: 100%;
          font-size: 0.5rem;
          text-align: center;
          position: absolute;
          top: 28vh;
        "
        v-if="user.nickName == undefined"
      >
        {{ $t(users.name) }}
      </p>
    </div>
    <div
      class="call"
      v-for="(item, index) in call"
      :key="index"
      @click="onClick(index)"
    >
      <p>
        <img :src="item.src" alt="" />
        <span>{{ $t(item.name) }}</span>
      </p>
      <van-icon name="arrow" />
    </div>
    <div class="call" @click="loginOut">
      <p>
        <img
          src="../assets/images/tuichudenglu.png"
          alt=""
          style="width: 0.3rem; height: 0.3rem"
        />
        <span>退出登录</span>
      </p>
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main";
// 导入底部导航栏组件
export default {
  components: {},
  data() {
    return {
      users: {
        name: "未命名",
        src: require("../assets/images/logo.png"),
      },
      user: {},
      call: [
        {
          src: require("@/assets/images/xin.png"),
          name: "个人信息",
        },
        {
          src: require("@/assets/images/zu.png"),
          name: "我的足迹",
        },
        {
          src: require("@/assets/images/shou.png"),
          name: "我的收藏",
        },
      ],
    };
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("wxUser")) || "";
    if (this.user == "") {
      this.$router.push("/login");
    }
  },
  methods: {
    loginOut() {
      sessionStorage.removeItem("wxUser");
      this.$router.push("/Home");
      eventBus.$emit("comeOn");
    },
    onClick(index) {
      if (index == 0) {
        this.$router.push("/person");
      }
      if (index == 1) {
        this.$router.push("/footprint");
      }
      if (index == 2) {
        this.$router.push("/collect");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mine {
  height: 92vh;
  background: #f8f7f7;
}
.hdader_bg {
  margin-bottom: 0.6rem;
  width: 100%;
  height: 35vh;
  background: url("../assets/images/hdader_bg.png") no-repeat center center;
  background-size: 100% 100%;
  background-color: #fff;
  .user {
    position: absolute;
    top: 20vh;
    left: 50%;
    transform: translate(-0.9rem, -0.9rem);
    p {
      padding: 0.2rem 0;
      font-size: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      // 单行文本溢出显示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
    }
  }
}
.call {
  width: 90%;
  height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 0.2rem 0 0.2rem;
  margin: 0 auto;
  margin-bottom: 0.2rem;
  p {
    display: flex;
    align-items: center;
    img {
      width: 0.4rem;
      height: 0.4rem;
    }
    span {
      font-size: 0.36rem;
      color: rgba(0, 0, 0, 0.800000011920929);
      margin-left: 0.05rem;
    }
  }
  i {
    font-size: 0.4rem;
    color: #999;
  }
}
</style>